<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <path
      d="M6 2.00098L3 6.00098V20.001C3 20.5314 3.21071 21.0401 3.58579 21.4152C3.96086 21.7903 4.46957 22.001 5 22.001H19C19.5304 22.001 20.0391 21.7903 20.4142 21.4152C20.7893 21.0401 21 20.5314 21 20.001V6.00098L18 2.00098H6Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M3 6H21" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M15.9922 9.99902C15.9922 11.0599 15.5708 12.0773 14.8206 12.8275C14.0705 13.5776 13.0531 13.999 11.9922 13.999C10.9313 13.999 9.91391 13.5776 9.16376 12.8275C8.41361 12.0773 7.99219 11.0599 7.99219 9.99902"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
